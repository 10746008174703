// import en from "./locales/en";
// import pl from "./locales/pl";
// import ru from "./locales/ru";
// const locales = { en, pl };

export default defineI18nConfig(() => ({
  legacy: false,
  locale: "en",
  globalInjection: true,
  fallbackLocale: "en",
  // messages: locales,
}));
